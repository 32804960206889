import React from 'react';

export default (props) => (
  <svg width={117} height={35} viewBox="0 0 116.7 34.7" {...props}>
    <path fill="#84F9CB" d="M10.1 33.5c-3.8 0-6.4-1.4-7.8-4.3-.5-1-.7-2.3-.7-3.8s.1-3.2.4-4.9c.5-3.5 1.6-6.7 3.1-9.6.8-1.5 1.6-2.8 2.6-3.9 2.1-2.3 4.5-3.5 7-3.5 1.1 0 2 .3 2.7.9.7.6 1 1.5 1 2.7 0 1.2-.3 2.4-1 3.5-.7 1.2-1.6 2.4-2.7 3.7-1.1 1.3-2.3 2.6-3.6 4-1.3 1.4-2.6 2.9-3.8 4.5v1.6c0 1.5.3 2.6 1 3.3.6.7 1.4 1 2.2 1 2.3 0 4.3-1.1 6.1-3.4.7-.9 1.3-1.4 1.6-1.4.4 0 .6.5.6 1.4 0 .9-.2 1.8-.7 2.8-.5.9-1.1 1.8-1.9 2.6-.8.8-1.7 1.4-2.8 1.9s-2.2.9-3.3.9zM12.3 12c.6-.7 1-1.3 1.3-1.9.3-.6.5-1.1.5-1.7 0-.6-.2-.9-.6-.9-.9 0-2 1-3.1 2.9s-2 4.4-2.5 7.6c.9-1.4 1.7-2.5 2.5-3.5.7-1 1.3-1.8 1.9-2.5z"/>
    <path fill="#84F9CB" d="M20.6 33.5c-2.7 0-4-2.5-4-7.5 0-2.7.3-5.2 1-7.4 1-3.4 2.2-5.1 3.5-5.1.8 0 1.4.5 1.8 1.6.4.8.5 1.6.5 2.4 0 1.1-.3 2.9-1 5.6-.6 2.3-.9 3.8-.9 4.4s.1.9.4 1.2c.3.2.6.3 1 .3s.9-.3 1.5-.8 1.2-1.1 1.8-1.7c.6-.6 1.1-1.2 1.6-1.7s.8-.8 1-.8c.4 0 .6.5.6 1.4 0 1.9-.9 3.7-2.8 5.5-1.8 1.7-3.8 2.6-6 2.6zm1.7-23.3c-.7-1.1-1.1-2.2-1.1-3.4s.2-2.2.6-3.1c.4-.8.8-1.4 1.1-1.8.3-.4.7-.6 1.2-.6.7 0 1.4.6 2 1.7.6 1.1.9 2 .9 2.8 0 1.4-.5 2.5-1.5 3.3-.9.7-2 1.1-3.2 1.1z"/>
    <path fill="#84F9CB" d="M48.8 21.2c0-.9-.5-1.3-1.5-1.3-.7 0-1.5.7-2.3 2s-1.5 2.8-1.8 4.3v.1c0 .9.1 1.8.4 2.8.3 1 .4 1.6.4 2 0 .9-1.1 1.8-3.4 2.7-.3.1-.7.2-.9.2-.7 0-1.1-.3-1.4-1-.3-.7-.4-1.6-.4-2.8s0-2.3.1-3.4.1-2 .2-2.9c.1-.9.1-1.7.2-2.4s.1-1.2.1-1.7c0-.9-.4-1.4-1.2-1.4-.8 0-1.8 1-2.9 2.9-1.1 1.9-1.6 3.5-1.6 4.6 0 1.1.1 2.2.4 3.1.3 1 .4 1.6.4 2 0 .9-1.1 1.8-3.4 2.7-.3.1-.7.2-.9.2-.7 0-1.1-.3-1.4-1-.3-.7-.4-1.8-.4-3.2 0-3.8.1-6.5.4-8.2.4-2.5 1.3-4.8 2.7-7 .5-.7 1-1.1 1.6-1.1.9 0 1.7.7 2.4 2.2.2.4.4.7.5 1 1.3-1.6 2.8-2.4 4.5-2.4 1.1 0 2.2.4 3.1 1.1.9.8 1.4 1.7 1.4 2.9v.4c1.6-2 3.6-3 6-3 1.4 0 2.5.4 3.3 1.1.7.7 1.1 1.7 1.1 2.8 0 1.1-.1 2.1-.2 3s-.3 1.7-.5 2.5-.4 1.5-.5 2.1c-.2.6-.2 1.1-.2 1.4 0 .3.1.5.3.5.4 0 .9-.3 1.5-.8s1.2-1.1 1.8-1.7c.6-.6 1.1-1.2 1.6-1.7s.8-.8 1-.8c.4 0 .6.5.6 1.4 0 1.9-.9 3.7-2.8 5.4-1.9 1.8-3.9 2.6-6 2.6-1.9 0-2.9-1.7-2.9-5.2 0-.9.1-2.1.4-3.6.1-1.6.2-2.7.2-3.4z"/>
    <path fill="#84F9CB" d="M66.4 29c2.1 0 4.1-.8 6-2.5.7-.6 1.3-1.2 1.8-1.7s.8-.8 1-.8c.4 0 .6.4.6 1.1 0 .7-.2 1.6-.6 2.5-.4.9-1.1 1.8-2 2.7-2.3 2.2-5.4 3.2-9.4 3.2-3.2 0-5.3-1.1-6.5-3.4-.4-.8-.6-1.9-.6-3.4s.3-3 .9-4.7c.6-1.6 1.4-3 2.3-4.2 1-1.2 2.1-2.1 3.4-2.7 1.3-.7 2.6-1 4.1-1 1.5 0 2.6.4 3.4 1.1.8.7 1.2 1.7 1.2 2.8 0 1.1-.3 2.1-.9 2.8-.6.7-1.3 1.3-2.2 1.8-.9.5-1.9.9-3 1.3s-2.2.8-3.3 1.3v1c0 1 .4 1.6 1.2 2s1.7.7 2.6.8zm-.6-11.3c-1.4 0-2.3 1.6-2.9 4.8.6-.4 1.1-.7 1.6-1 .5-.3.9-.6 1.3-.8.8-.6 1.2-1.3 1.2-2s-.4-1-1.2-1z"/>
    <path fill="#84F9CB" d="M82.2 33.5c-3.8 0-6.4-1.4-7.8-4.3-.5-1-.7-2.3-.7-3.8s.1-3.2.4-4.9c.5-3.5 1.6-6.7 3.1-9.6.8-1.5 1.6-2.8 2.6-3.8 2.1-2.3 4.5-3.5 7-3.5 1.1 0 2 .3 2.7.9.7.6 1 1.5 1 2.7 0 1.2-.3 2.4-1 3.5-.7 1.2-1.6 2.4-2.7 3.7-1.1 1.3-2.3 2.6-3.6 4-1.3 1.4-2.6 2.9-3.8 4.5v1.6c0 1.5.3 2.6 1 3.3.6.7 1.4 1 2.2 1 2.3 0 4.3-1.1 6.1-3.4.7-.9 1.3-1.4 1.6-1.4.4 0 .6.5.6 1.4 0 .9-.2 1.8-.7 2.8s-1.2 1.8-2 2.6-1.7 1.4-2.8 1.9-2.1.8-3.2.8zM84.4 12c.6-.7 1-1.3 1.3-1.9.3-.6.5-1.1.5-1.7 0-.6-.2-.9-.6-.9-.9 0-2 1-3.1 2.9s-2 4.4-2.5 7.6c.9-1.4 1.7-2.5 2.5-3.5s1.3-1.8 1.9-2.5z"/>
    <path fill="#84F9CB" d="M92.7 33.5c-2.7 0-4-2.5-4-7.5 0-2.7.3-5.2 1-7.4 1-3.4 2.2-5.1 3.5-5.1.8 0 1.4.5 1.8 1.6.4.8.5 1.6.5 2.4 0 1.1-.3 2.9-1 5.6-.6 2.3-.9 3.8-.9 4.4s.1.9.4 1.2c.3.2.6.3 1 .3s.9-.3 1.5-.8 1.2-1.1 1.8-1.7c.6-.6 1.1-1.2 1.6-1.7s.8-.8 1-.8c.4 0 .6.5.6 1.4 0 1.9-.9 3.7-2.8 5.5-1.8 1.7-3.8 2.6-6 2.6zm1.7-23.3c-.7-1.1-1.1-2.2-1.1-3.4s.2-2.2.6-3.1c.4-.8.8-1.4 1.1-1.8.3-.4.7-.6 1.2-.6.7 0 1.4.6 2 1.7.6 1.1.9 2 .9 2.8 0 1.4-.5 2.5-1.5 3.3-.9.7-2 1.1-3.2 1.1z"/>
    <path fill="#84F9CB" d="M100.8 31.2c-.3-.7-.5-1.5-.7-2.5-.1-.9-.2-2.3-.2-4 0-1.7.3-4 1-6.9h-1.8c-.3 0-.5-.1-.5-.4 0-.6.1-1.2.2-1.6.1-.4.4-.9.9-1.4h2.2c1.3-4.1 2.6-6.2 3.9-6.2 1.1 0 1.8.7 2.2 2 .1.4.2.8.4 1.2l-1.6 2.9c1.9.1 3 .1 3.5.1s.8 0 .8.1.1.2.1.4c0 .5-.1 1.1-.3 1.8-.2.7-.5 1.1-.8 1l-4-.2c-.6 3.3-.8 5.9-.8 8.1s.3 3.2.9 3.2c.5 0 1.4-.4 2.8-1.3 1.3-.8 2.3-1.6 2.9-2.4.6-.8 1.1-1.2 1.4-1.2.4 0 .6.5.6 1.4 0 .9-.3 1.8-1 2.8s-1.4 1.8-2.4 2.6-2.1 1.4-3.2 1.9-2.2.8-3.1.8-1.6-.2-2-.6c-.7-.4-1.1-.9-1.4-1.6z"
    fillRule="evenodd"
    />
  </svg>
);

